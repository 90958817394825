'use strict';

// https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    value: function(predicate) {
     // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      var thisArg = arguments[1];

      // 5. Let k be 0.
      var k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        var kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
    configurable: true,
    writable: true
  });
}

function BackgroundNode(_ref) {
  var node = _ref.node,
      loadedClassName = _ref.loadedClassName;

  var src = node.getAttribute('data-background-image-url');
  var show = function show(onComplete) {
    requestAnimationFrame(function () {
      node.style.backgroundImage = 'url(' + src + ')';
      node.classList.add(loadedClassName);
      onComplete();
    });
  };

  return {
    node: node,

    // onComplete is called after the image is done loading.
    load: function load(onComplete) {
      var img = new Image();
      img.onload = show(onComplete);
      img.src = src;
    }
  };
}

var defaultOptions = {
  selector: '[data-background-image-url]',
  loadedClassName: 'loaded'
};

function BackgroundLazyLoader() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultOptions,
      selector = _ref2.selector,
      loadedClassName = _ref2.loadedClassName;

  var nodes = [].slice.apply(document.querySelectorAll(selector)).map(function (node) {
    return new BackgroundNode({ node: node, loadedClassName: loadedClassName });
  });

  var callback = function callback(entries, observer) {
    entries.forEach(function (_ref3) {
      var target = _ref3.target,
          isIntersecting = _ref3.isIntersecting;

      if (!isIntersecting) {
        return;
      }

      var obj = nodes.find(function (it) {
        return it.node.isSameNode(target);
      });

      if (obj) {
        obj.load(function () {
          // Unobserve the node:
          observer.unobserve(target);
          // Remove this node from our list:
          nodes = nodes.filter(function (n) {
            return !n.node.isSameNode(target);
          });

          // If there are no remaining unloaded nodes,
          // disconnect the observer since we don't need it anymore.
          if (!nodes.length) {
            observer.disconnect();
          }
        });
      }
    });
  };

  var observer = new IntersectionObserver(callback);
  nodes.forEach(function (node) {
    return observer.observe(node.node);
  });
}

BackgroundLazyLoader();